import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 300.000000 281.000000" >
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,281.000000) scale(0.100000,-0.100000)">
 
<path d="M1380 2480 c-273 -34 -538 -179 -714 -392 -70 -84 -133 -184 -123
-194 2 -3 17 17 32 43 121 208 336 385 568 468 152 54 246 68 432 63 94 -3
146 -1 125 3 -57 14 -240 18 -320 9z"/>
<path d="M1753 2348 c-5 -7 -9 -29 -8 -47 2 -44 25 -55 25 -12 0 17 -4 31 -10
31 -5 0 -10 5 -10 10 0 13 67 13 75 1 3 -6 -4 -15 -15 -21 -25 -13 -29 -63 -4
-53 11 4 12 8 4 13 -9 6 -7 11 6 19 11 6 15 11 10 11 -5 0 -3 6 3 14 10 11 7
17 -11 30 -27 19 -52 21 -65 4z"/>
<path d="M1488 2323 c-19 -22 -32 -56 -18 -48 6 3 10 -5 10 -19 0 -14 5 -26
11 -26 6 0 8 9 4 21 -6 18 -4 20 17 15 l23 -6 -22 11 c-40 21 -20 69 22 53 23
-9 18 -24 -7 -25 -22 -1 -22 -1 2 -8 15 -4 24 -14 24 -24 -1 -9 2 -17 7 -17
23 0 6 -18 -23 -25 -22 -5 -27 -9 -16 -12 30 -8 59 15 54 42 -13 71 -56 105
-88 68z"/>
<path d="M1904 2306 c-6 -7 -17 -23 -24 -35 -10 -19 -10 -25 1 -32 10 -6 15
-1 17 19 4 36 29 55 53 41 23 -12 24 -46 2 -71 -15 -17 -15 -18 7 -18 13 0 21
3 17 6 -3 3 -1 19 5 35 8 24 7 34 -7 49 -20 23 -55 25 -71 6z"/>
<path d="M1342 2279 c-12 -5 -22 -11 -22 -13 0 -3 7 -3 15 0 11 4 19 -3 26
-25 6 -17 15 -31 20 -31 12 0 11 12 -4 47 -10 26 -15 29 -35 22z"/>
<path d="M1403 2250 c6 -22 17 -40 24 -40 11 0 10 7 -2 36 -8 20 -15 38 -15
40 0 2 -4 4 -9 4 -4 0 -3 -18 2 -40z"/>
<path d="M2215 2247 c82 -65 223 -214 274 -290 66 -98 124 -218 158 -325 14
-45 27 -81 29 -79 3 2 -7 43 -21 91 -48 165 -149 338 -275 470 -73 76 -180
167 -197 165 -4 0 10 -14 32 -32z"/>
<path d="M2057 2251 c4 -9 2 -13 -2 -9 -9 9 -45 -45 -45 -67 0 -16 47 -51 56
-42 3 3 -5 11 -18 18 -12 6 -24 13 -26 14 -2 2 9 20 23 41 26 38 38 42 61 23
16 -13 -5 -69 -26 -69 -13 0 -13 -1 0 -10 22 -14 26 -13 38 15 23 50 7 85 -45
97 -17 5 -20 2 -16 -11z"/>
<path d="M1317 2234 c3 -7 9 -14 14 -14 4 0 5 -7 2 -17 -4 -11 -3 -14 5 -9 7
4 12 2 12 -4 0 -6 5 -8 10 -5 6 4 4 12 -5 21 -8 9 -15 19 -15 24 0 4 -7 10
-14 13 -10 4 -13 1 -9 -9z"/>
<path d="M1248 2200 c9 -11 25 -29 35 -41 10 -11 22 -17 25 -13 4 3 1 9 -5 11
-7 3 -13 10 -13 17 0 13 -35 46 -50 46 -6 0 -2 -9 8 -20z"/>
<path d="M1216 2185 c7 -27 51 -80 51 -62 0 8 -13 30 -28 48 -20 24 -26 28
-23 14z"/>
<path d="M2181 2147 c-13 -6 -31 -23 -40 -37 -15 -24 -15 -25 4 -9 11 9 38 26
60 38 27 14 33 21 20 21 -11 0 -31 -6 -44 -13z"/>
<path d="M2232 2113 c-23 -64 -24 -70 -2 -44 11 13 20 34 20 47 0 31 -5 30
-18 -3z"/>
<path d="M1098 2112 c-12 -15 -11 -16 5 -3 15 12 21 11 43 -10 20 -18 27 -21
33 -10 6 9 11 10 15 2 9 -13 26 -15 26 -2 0 10 -52 31 -87 36 -12 2 -28 -4
-35 -13z"/>
<path d="M1114 2069 c25 -36 53 -51 75 -40 10 6 20 8 23 6 3 -3 5 2 5 10 0 19
-6 19 -28 -1 -17 -15 -20 -14 -45 15 -29 35 -53 43 -30 10z"/>
<path d="M1063 2017 c15 -12 27 -29 27 -36 0 -11 3 -11 16 0 10 8 12 15 5 17
-6 2 -8 9 -4 15 3 7 3 9 -2 5 -4 -4 -13 -4 -19 1 -6 4 -20 11 -31 14 -15 5
-13 2 8 -16z"/>
<path d="M1007 2004 c-13 -17 -15 -29 -8 -41 29 -54 98 -69 118 -26 14 30 -1
30 -22 1 -10 -15 -18 -19 -23 -12 -4 6 -22 19 -39 29 -18 11 -33 23 -33 27 0
12 18 19 25 8 3 -5 15 -15 26 -20 15 -8 19 -7 19 4 0 8 -9 17 -19 21 -11 3
-21 12 -23 20 -2 9 -9 5 -21 -11z"/>
<path d="M2337 1944 c-23 -13 -26 -18 -16 -30 10 -12 14 -12 26 6 19 27 60 26
69 -2 5 -16 1 -27 -16 -40 -13 -11 -28 -17 -35 -15 -6 3 -11 -3 -11 -13 0 -15
3 -15 33 3 37 22 50 55 33 86 -13 25 -43 26 -83 5z"/>
<path d="M975 1883 c17 -9 32 -19 35 -24 3 -4 14 0 25 8 14 11 15 14 3 10 -9
-3 -24 1 -35 8 -10 8 -27 14 -38 14 -16 0 -14 -3 10 -16z"/>
<path d="M940 1862 c0 -4 9 -20 19 -36 21 -32 48 -43 48 -20 0 10 -4 11 -14 3
-16 -14 -19 11 -2 28 7 7 2 14 -20 21 -17 6 -31 8 -31 4z"/>
<path d="M2412 1807 c-18 -8 -31 -27 -18 -27 13 0 66 23 66 29 0 11 -22 9 -48
-2z"/>
<path d="M2475 1803 c4 -15 -1 -26 -17 -37 -22 -16 -22 -16 15 -16 28 0 36 3
30 13 -4 6 -8 14 -8 17 0 3 -6 14 -13 25 -13 19 -13 19 -7 -2z"/>
<path d="M2435 1701 c-27 -13 -34 -20 -20 -20 11 0 38 8 60 18 27 13 34 20 20
20 -11 0 -38 -8 -60 -18z"/>
<path d="M2440 1628 c0 -5 -4 -7 -9 -3 -5 3 -13 0 -16 -5 -11 -17 -13 -60 -4
-60 5 0 9 15 10 33 0 17 3 26 6 19 4 -10 8 -10 18 -2 8 6 26 10 42 8 22 -2 29
-9 31 -30 3 -22 -2 -29 -22 -34 -14 -3 -32 -4 -40 -1 -8 3 -17 -1 -20 -9 -4
-10 3 -14 27 -14 37 0 50 7 64 35 11 20 1 64 -14 66 -40 6 -73 4 -73 -3z"/>
<path d="M2460 1584 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z"/>
<path d="M2442 1477 c7 -8 31 -16 51 -19 51 -6 27 17 -27 27 -34 6 -37 5 -24
-8z"/>
<path d="M2457 1421 c-4 -16 -1 -17 24 -8 19 8 24 14 16 19 -20 13 -34 9 -40
-11z"/>
<path d="M410 1338 c0 -239 116 -548 269 -717 37 -41 52 -36 19 5 -13 16 -32
45 -42 64 -11 19 -25 39 -33 43 -7 4 -13 13 -13 18 0 6 5 7 10 4 12 -8 -25 89
-45 119 -18 25 -19 37 -2 30 7 -2 22 -19 32 -37 10 -18 32 -44 48 -59 16 -15
26 -31 23 -36 -2 -4 4 -9 15 -9 18 -2 17 1 -6 30 -151 185 -223 337 -247 519
-6 48 -15 88 -20 88 -4 0 -8 -28 -8 -62z"/>
<path d="M2420 1341 c0 -3 16 -16 35 -29 52 -33 46 -52 -17 -52 -39 0 -49 -3
-38 -10 18 -12 92 -2 102 13 8 13 -9 49 -29 60 -7 5 -12 11 -9 15 2 4 -7 8
-20 8 -13 1 -24 -1 -24 -5z"/>
<path d="M2367 1185 c-6 -15 13 -25 49 -25 13 0 24 -4 24 -10 0 -5 -7 -10 -15
-10 -16 0 -20 -16 -6 -24 13 -9 34 21 27 39 -3 9 -14 18 -23 21 -10 3 -25 9
-35 14 -11 6 -18 5 -21 -5z"/>
<path d="M2686 1137 c-13 -134 -96 -344 -187 -478 -101 -149 -265 -285 -413
-344 -124 -48 -257 -44 -368 11 -56 28 -55 28 -55 10 0 -7 15 -19 34 -27 18
-7 33 -16 33 -19 0 -3 28 -15 62 -25 56 -17 59 -19 37 -27 -13 -6 -71 -14
-129 -18 -58 -5 -134 -14 -168 -20 -55 -10 -78 -9 -165 9 -56 11 -142 36 -192
56 -121 49 -128 51 -70 19 140 -75 283 -103 510 -99 195 4 263 18 432 89 137
58 254 138 350 240 134 141 220 293 272 478 21 73 39 208 29 208 -3 0 -8 -28
-12 -63z m-901 -927 c-11 -5 -54 -8 -95 -7 l-75 1 80 6 c44 3 87 7 95 7 8 1 6
-2 -5 -7z"/>
<path d="M2346 1142 c-4 -3 6 -16 20 -29 31 -27 41 -24 22 6 -14 23 -33 33
-42 23z"/>
<path d="M2258 1073 c-22 -25 -31 -60 -18 -68 6 -3 7 -11 4 -17 -4 -7 -2 -8 5
-4 6 4 18 0 27 -8 8 -9 26 -16 38 -16 45 0 68 58 36 90 -11 11 -20 17 -20 13
0 -5 -5 0 -11 10 -13 23 -25 22 -25 -2 0 -11 3 -18 7 -16 12 8 49 -27 49 -46
0 -24 -21 -33 -55 -25 -17 4 -25 13 -25 29 0 17 -2 19 -10 7 -5 -8 -12 -12
-15 -8 -4 4 3 20 16 37 24 33 22 52 -3 24z"/>
<path d="M1479 458 c-1 -2 -2 -20 -3 -41 -2 -28 2 -41 16 -48 10 -5 18 -8 19
-7 7 27 5 35 -10 34 -12 -1 -16 7 -15 32 0 27 -3 40 -7 30z"/>
<path d="M1408 372 c51 -26 90 -51 85 -55 -4 -5 1 -5 11 -1 13 5 17 4 12 -4
-5 -8 -1 -10 10 -5 11 4 13 8 6 11 -7 2 -9 9 -6 14 4 7 2 8 -4 4 -6 -4 -52 14
-101 39 -50 25 -94 45 -98 44 -5 0 34 -21 85 -47z"/>
<path d="M1610 381 c0 -5 8 -15 18 -20 13 -8 14 -10 2 -11 -13 0 -13 -1 0 -10
21 -14 29 -12 26 5 -3 18 -46 51 -46 36z"/>
<path d="M1110 331 c17 -12 195 -72 280 -95 30 -8 46 -15 35 -15 -36 -1 -211
50 -317 94 -10 4 -18 5 -18 2 0 -11 164 -68 266 -93 104 -25 166 -26 219 -2
14 6 10 7 -15 3 -19 -3 -55 -8 -80 -10 l-45 -5 50 14 c54 14 105 34 92 35 -5
1 -31 -6 -58 -15 -48 -16 -54 -16 -137 6 -73 19 -149 44 -267 85 -16 6 -17 5
-5 -4z"/>
<path d="M1170 337 c0 -7 190 -70 196 -65 2 3 -35 17 -84 33 -48 15 -93 30
-99 33 -7 2 -13 2 -13 -1z"/>
<path d="M1470 300 c12 -8 12 -10 -5 -11 -20 0 -20 0 0 -9 19 -8 19 -9 1 -9
-11 -1 -17 -4 -13 -7 3 -3 15 -3 28 1 17 6 19 9 8 16 -12 7 -11 9 1 9 13 0 13
1 0 10 -8 5 -19 10 -25 10 -5 0 -3 -5 5 -10z"/>
<path d="M1673 292 c-9 -14 -14 -15 -31 -5 -17 10 -23 9 -38 -8 -9 -10 -12
-19 -6 -19 6 0 12 4 14 9 2 6 21 6 48 0 25 -6 68 -12 95 -14 l50 -4 -54 9
c-29 4 -58 14 -65 20 -8 10 -5 11 14 5 14 -4 23 -6 20 -3 -32 26 -36 27 -47
10z"/>
<path d="M1525 280 c18 -7 18 -8 -8 -15 -15 -4 -26 -10 -23 -12 2 -3 21 1 41
8 29 11 33 15 18 20 -10 4 -25 8 -33 8 -9 0 -7 -4 5 -9z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
